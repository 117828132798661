import {
  Popover,
  ActionIcon,
  createTheme,
  defaultVariantColorsResolver,
  type DefaultMantineColor,
  type MantineColorsTuple,
  Paper,
  Skeleton } from
'@mantine/core';

import classes from './theme.module.css';

// Theme configuration is merged with default theme
export const theme = createTheme({
  fontFamily: [
  'Roboto Flex Variable',
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Helvetica',
  'Arial',
  'sans-serif',
  'Apple Color Emoji',
  'Segoe UI Emoji'].
  join(','),
  colors: {
    navy: ['#EBEEFA', '#CED7E5', '#B2BCCE', '#94A1B8', '#7E8CA6', '#687995', '#5A6A84', '#49576D', '#3A4457', '#27303F'],
    secondary: ['#E1F6F6', '#B4EAE7', '#80DDD8', '#47CEC7', '#13C1B8', '#00B4A8', '#00A69A', '#009588', '#008478', '#006759'],
    surface: ['#EDEFF2', '#D1D8DE', '#B4BEC8', '#96A4B2', '#8090A1', '#697D90', '#5C6E7F', '#4C5A68', '#3D4753', '#2B333B'],
    surfaceAlpha: [
    '#EDEFF2BF',
    '#D1D8DEBF',
    '#B4BEC8BF',
    '#96A4B2BF',
    '#8090A1BF',
    '#697D90BF',
    '#5C6E7FBF',
    '#4C5A68BF',
    '#3D4753BF',
    '#2B333BBF']

  },
  primaryColor: 'navy',
  primaryShade: 9,
  variantColorResolver: (input) => {
    const defaultResolvedColors = defaultVariantColorsResolver(input);

    if (input.variant === 'filled' && input.color === 'secondary') {
      return {
        background: 'var(--mantine-color-secondary-2)',
        hover: 'var(--mantine-color-secondary-3)',
        border: 'none',
        color: 'var(--mantine-primary-color-filled)'
      };
    }

    return defaultResolvedColors;
  },
  components: {
    Popover: Popover.extend({ defaultProps: { radius: 'lg' } }),
    ActionIcon: ActionIcon.extend({ defaultProps: { radius: 'xl' } }),
    Paper: Paper.extend({ classNames: { root: classes.paperRoot } }),
    Skeleton: Skeleton.extend({ classNames: { root: classes.skeletonRoot } })
  }
});

type ExtendedCustomColors = 'navy' | 'secondary' | DefaultMantineColor;

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, MantineColorsTuple>;
  }
}