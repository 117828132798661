import globalStyles from '@/theme/global.css';
import "@fontsource-variable/roboto-flex/index.css?__remix_sideEffect__";
// All packages except `@mantine/hooks` require styles imports
import "@mantine/core/styles.css?__remix_sideEffect__";
import "@mantine/dates/styles.css?__remix_sideEffect__";
import "@mantine/notifications/styles.css?__remix_sideEffect__";

import { cssBundleHref } from '@remix-run/css-bundle';
import { json, type LinksFunction, type LoaderFunctionArgs, type MetaFunction } from '@remix-run/node';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  type ShouldRevalidateFunction } from
'@remix-run/react';
import type { ReactNode } from 'react';

import { ColorSchemeScript, MantineProvider, AppShell, Stack, ScrollArea } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { Notifications } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';
import { useChangeLanguage } from 'remix-i18next/react';

// import logoSrc from '@/agv.png';
import { GeneralErrorBoundary } from '@/components/ErrorBoundary';
import { NavbarMenus } from '@/components/NavbarMenus';
import { i18n_NS } from '@/i18n';
import { i18nRemix } from '@/i18n/i18next.server';
import { theme } from '@/theme';
import { getEnv } from '@/utils/env.server';
import { useNonce } from '@/utils/nonceProvider';

export const meta: MetaFunction = ({ error }) => [{ title: error ? 'Oops!' : 'AGV' }];

export const links: LinksFunction = () => [
// Preload CSS as a resource to avoid render blocking
// { rel: 'preload', href: logoSrc, as: 'image' },
{ rel: 'preload', href: globalStyles, as: 'style' },
...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : []),
{ rel: 'stylesheet', href: globalStyles }];


export const loader = async ({ request }: LoaderFunctionArgs) => {
  const locale = await i18nRemix.getLocale(request);
  return json({ locale, ENV: getEnv() });
};

// INFO: if more languages are added this func should check Accept-Language header
export const shouldRevalidate: ShouldRevalidateFunction = () => false;

export const handle = {
  // In the handle export, we can add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  // TIP: In most cases, you should set this to your defaultNS from your i18n config
  // or if you did not set one, set it to the i18next default namespace "translation"
  i18n: i18n_NS.DEFAULT
};

const DocumentComponent = (props: {
  nonce: string;
  locale: string;
  dir: string;
  children: ReactNode;
  env?: Record<string, string>;
}) => {
  const { children, dir, locale, nonce, env = {} } = props;
  return (
    <html lang={locale} dir={dir}>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <Meta />
        <Links />
        <ColorSchemeScript nonce={nonce} />
      </head>
      <body>
        <MantineProvider theme={theme}>
          <DatesProvider settings={{ locale }}>
            <Notifications />
            {children}
            <ScrollRestoration nonce={nonce} />
            <script
              nonce={nonce}
              dangerouslySetInnerHTML={{
                __html: `globalThis.ENV = ${JSON.stringify(env)}`
              }} />

            <Scripts nonce={nonce} />
            <LiveReload nonce={nonce} />
          </DatesProvider>
        </MantineProvider>
      </body>
    </html>);

};

const Layout = () => {
  return (
    <AppShell navbar={{ width: 80, breakpoint: 0 }}>
      <AppShell.Navbar withBorder={false} style={{ height: '100vh', background: 'var(--mantine-primary-color-filled)' }}>
        {/* <AppShell.Section p={16}>
           <Image src={logoSrc} width={47} height={47} alt='logo' draggable='false' />
          </AppShell.Section> */}
        <AppShell.Section grow>
          <ScrollArea h='100vh'>
            <Stack align='center' pt={65} pb='md'>
              <NavbarMenus />
            </Stack>
          </ScrollArea>
        </AppShell.Section>
      </AppShell.Navbar>

      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>);

};

export default function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const { locale } = useLoaderData<typeof loader>();
  const { i18n } = useTranslation();

  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  useChangeLanguage(locale);

  return (
    <DocumentComponent locale={locale} dir={i18n.dir()} nonce={nonce} env={data.ENV}>
      <Layout />
    </DocumentComponent>);

}

export function ErrorBoundary() {
  const nonce = useNonce();
  const { i18n } = useTranslation();

  return (
    <DocumentComponent locale={i18n.resolvedLanguage ?? i18n.language} dir={i18n.dir()} nonce={nonce}>
      <GeneralErrorBoundary />
    </DocumentComponent>);

}